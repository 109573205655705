import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "./../services/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import ImportSupplier from "./ImportSupplier";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
import "./../flag.css";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import axios from "axios";
import Storage from "../utils/storage";
import { Link } from "react-router-dom";
import Config from "./../Config";
import { useNavigate } from "react-router-dom";
import { isInternalEnricher, isExternalEnricher, isSupplier, isEnricher } from "../utils/roleValidator";
import { getAccessToken, deleteStoredData } from "../utils/http";
import ItemsDataTable from "../components/ItemsDataTable";
import { FilterMatchMode } from "primereact/api";
import { Status, isStatusInProgress, isStatusApproved } from "../utils/itemStatusValidator";
import { itemList, materialSubTypeDropdown, materialTypesDropdown, primaryCategoryDropdown, secondaryCategoryDropdown, secondarySubCategoryDropdown, subCategoryDropdown } from "../services/ApproverService";
import { saveProducts, saveProductsUpdate, viewDetails, supplierItemList, DeactivateItem } from "../services/ItemListService";
import { productUseByConsumer, plasticFilmPrePost, hfmEnricherCodes, IncludesPFAS } from "../constants/ItemFields";
import { showErrorToast, showErrorToastNullable, showSuccessToast, showWarningToast } from "../services/ToastService";

export default function ItemsList(props) {
  const userId = Storage.get("user");
  const navigate = useNavigate();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [selectedCustomPrinted, setselectedCustomPrinted] = useState(null);
  const [selectedCustomerExclusive, setselectedCustomerExclusives] =
    useState(null);
  const [selectedIncludePFA, setselectedIncludePFA] = useState(null);
  const [selectedIncludePthalates, setselectedIncludePthalates] =
    useState(null);
  const [selectedProductConsumer, setselectedProductConsumer] = useState(null);
  const [selectedPlasticFilmEmbossed, setselectedPlasticFilmEmbossed] =
    useState(null);
  const [selectedCoatedPaper, setselectedCoatedPaper] = useState(null);
  const [selectedProductTypeLid, setselectedProductTypeLid] = useState(null);
  const [selectedProductTypeLidDropdown, setselectedProductTypeLidDropdown] =
    useState(null);
  const [
    selectedPlasticFilmPrePostEmbossed,
    setselectedPlasticFilmPrePostEmbossed,
  ] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryDropdown, setSelectedCountryDropdown] = useState(null);
  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedReusable, setSelectedReusable] = useState(null);
  const [selectedReusableDropdown, setSelectedReusableDropdown] =
    useState(null);
  const [selectedColorsDropdown, setSelectedColorsDropdown] = useState(null);
  const [selectedCertifications1, setSelectedCertifications1] = useState(null);
  const [selectedCertifications2, setSelectedCertifications2] = useState(null);
  const [selectedCertifications3, setSelectedCertifications3] = useState(null);
  const [selectedCertificationsDropdown, setSelectedCertificationsDropdown] =
    useState(null);
  const [selectedCompostable, setSelectedCompostable] = useState(null);
  const [selectedCompostableDropdown, setSelectedCompostableDropdown] =
    useState(null);
  const [selectedMaterialType, setselectedMaterialType] = useState(null);
  const [selectedMaterialTypeDropdown, setselectedMaterialTypeDropdown] =
    useState(null);
  const [selectedMaterialTypeLid, setselectedMaterialTypeLid] = useState(null);
  const [selectedMaterialTypeLidDropdown, setselectedMaterialTypeLidDropdown] =
    useState(null);
  const [selectedMaterialSubType, setselectedMaterialSubType] = useState(null);
  const [selectedMaterialSubTypeDropdown, setselectedMaterialSubTypeDropdown] =
    useState(null);
  const [selectedMaterialSubTypeLid, setselectedMaterialSubTypeLid] =
    useState(null);
  const [
    selectedMaterialSubTypeLidDropdown,
    setselectedMaterialSubTypeLidDropdown,
  ] = useState(null);
  const [selectedPrimaryCategory, setselectedPrimaryCategory] = useState(null);
  const [selectedPrimaryCategoryDropdown, setselectedPrimaryCategoryDropdown] =
    useState(null);
  const [selectedSecondaryCategory, setselectedSecondaryCategory] =
    useState(null);
  const [
    selectedSecondaryCategoryDropdown,
    setselectedSecondaryCategoryDropdown,
  ] = useState();
  const [selectedSecondarySubCategory, setselectedSecondarySubCategory] =
    useState(null);
  const [
    selectedSecondarySubCategoryDropdown,
    setselectedSecondarySubCategoryDropdown,
  ] = useState(null);
  const [selectedSubCategory, setselectedSubCategory] = useState(null);
  const [selectedSubCategoryDropdown, setselectedSubCategoryDropdown] =
    useState(null);
  const [products, setProducts] = useState(null);
  const [rowdata, setRowData] = useState(null);
  const [tabDialog, setTabDialog] = useState(null);
  const [errorDialog, setErrorDialog] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [rejectedComments, setRejectedComment] = useState(null);
  const [importDialog, setImportDialog] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState({});
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectPdfFile, setSelectPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");
  const [viewPdf, setViewPdf] = useState(null);
  const [file, setFile] = useState();
  const [error, setError] = useState("");
  const [array, setArray] = useState([]);
  const [isdisabled, setIsDisabled] = useState(false);
  const [isvalid, setIsValid] = useState(false);
  const [isvalidPlasticFilmThinkness, setIsValidPlasticFilmThinkness] =
    useState(false);
  const [isvalidNetProductWeight, setIsValidNetProductWeight] = useState(false);
  const [isvalidGrossProductWeight, setIsValidGrossProductWeight] =
    useState(false);
  const [isvalidSupplierPartNumber, setIsValidSupplierPartNumber] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [FDCId, setFDCId] = useState(null);
  const [BeverageCupId, setBeverageCupId] = useState(null);
  const [PlasticFilmId, setPlasticFilm] = useState(null);
  const [LidId, setLid] = useState(null);
  const [LidContainerId, setLidContainer] = useState(null);
  const [LidCupId, setLidCup] = useState(null);
  const [PaperId, setPaper] = useState(null);
  const [PaperboardId, setPaperboard] = useState(null);
  const [Paperboard_BagasseId, setPaperboard_Bagasse] = useState(null);
  const [Paperboard_Molded_PulpId, setPaperboard_Molded_Pulp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const token = getAccessToken();
  const supplierId = Storage.get("selectedSuppliers") || [];
  const Role = Storage.get("Role");
  //  console.log('suppliersitemlist',supplierId)
  const fileReader = new FileReader();
  const allowedExtensions = ["csv"];
  const toast = useRef(null);
  const toastBC = useRef(null);
  const dt = useRef(null);
  const [deactivationComments, setDeactivationComments] = useState("");
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [isFoundOnline, setIsFoundOnline] = useState(true);
  const [websiteAddress, setWebsiteAddress] = useState('');
  const [selectedHFMInternalCode, setselectedHFMInternalCode] = useState(null);
  const [selectedHFMExternalCode, setselectedHFMExternalCode] = useState(null);
  const [notes, setNotes] = useState("");
  const includesPFASOptions = IncludesPFAS(Role);
  const [sizeOptions] = useState([
    { label: "Accessories", value: "selectedProducts.Category" },
    { label: "Fitness", value: "selectedProducts.Category" },
    { label: "Clothing", value: "selectedProducts.Category" },
  ]);
  const [size, setSize] = useState(sizeOptions[1].value);
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [statusFilterValue, setStatusFilterValue] = useState([]);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: [
      {
        type: "global",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: null
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "Status.Status"
      }
    ]
});

  const getCountriesDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/counties`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedCountryDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getColorsDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/colors`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedColorsDropdown(res?.data);
        //   setData(res?.data);
      })

      .catch((e) => {});
  };

  const getCertificationsDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/certifications`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedCertificationsDropdown(res?.data);
        //   setData(res?.data);
      })

      .catch((e) => {});
  };

  const getMaterialTypeDropdown = () => {
    if (Storage.get("selectedMaterialTypeDropdown")) {
      setselectedMaterialTypeDropdown(Storage.get("selectedMaterialTypeDropdown"));
    } else {
      materialTypesDropdown(token,(res)=> {
        setselectedMaterialTypeDropdown(res);
        // FindId(res);
        Storage.set("selectedMaterialTypeDropdown", res);
      },
      (e) => {});
    }
  };

  const getMaterialTypeLidDropdown = () => {
    if (Storage.get("selectedMaterialTypeLidDropdown")) {
      setselectedMaterialTypeLidDropdown(Storage.get("selectedMaterialTypeLidDropdown"));
    } else {
      axios
      .get(`${Config.apiBaseAddress}/api/materialTypeLid`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setselectedMaterialTypeLidDropdown(res?.data);
        Storage.set("selectedMaterialTypeLidDropdown", res?.data);
      })

      .catch((e) => {});
    }
  };

  const FindId = (data) => {
    var product = data.find((obj) => {
      return obj.name === "Plastic_Film";
    });

    setPlasticFilm(product.id);

    var paper = data.find((obj) => {
      return obj.name === "Paper";
    });

    setPaper(paper.id);

    var paperboard = data.find((obj) => {
      return obj.name === "Paperboard";
    });
    setPaperboard(paperboard.id);

    var paperboard_Bagasse = data.find((obj) => {
      return obj.name === "Paperboard_Bagasse";
    });

    setPaperboard_Bagasse(paperboard_Bagasse.id);

    var paperboard_Molded_Pulp = data.find((obj) => {
      return obj.name === "Paperboard_Molded_Pulp";
    });

    setPaperboard_Molded_Pulp(paperboard_Molded_Pulp.id);
  };

  useEffect(() => {
    if (selectedMaterialTypeDropdown) {
      FindId(selectedMaterialTypeDropdown);
    }
  }, [selectedMaterialTypeDropdown]);
  const getMaterialSubTypeDropdown = () => {
    let materialSubTypes = Storage.get("materialSubTypes");
    if (materialSubTypes[selectedMaterialType]) {
      setselectedMaterialSubTypeDropdown(materialSubTypes[selectedMaterialType]);
    } else {
      materialSubTypeDropdown(selectedMaterialType,token,(res) => {
        setselectedMaterialSubTypeDropdown(res);
        materialSubTypes[selectedMaterialType] = res;
        Storage.set("materialSubTypes", materialSubTypes);
      },
      (e) => {});
    }
  };

  const getMaterialSubTypeLidDropdown = () => {
    axios
      .get(
        `${Config.apiBaseAddress}/api/GetMaterialSubTypesLid/${selectedMaterialTypeLid}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
            "Access-control-allow-origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setselectedMaterialSubTypeLidDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getPrimaryCategoryDropdown = () => {
    if (Storage.get("selectedPrimaryCategoryDropdown")) {
      setselectedPrimaryCategoryDropdown(Storage.get("selectedPrimaryCategoryDropdown"));
    } else {
      primaryCategoryDropdown(token,(res)=> {
         setselectedPrimaryCategoryDropdown(res);
         Storage.set("selectedPrimaryCategoryDropdown", res);
       },
       (e) => {});
    }
  };

  const getSecondaryCategoryDropdown = () => {
    let secondaryCategories = Storage.get("secondaryCategories");
    if (secondaryCategories[selectedPrimaryCategory]) {
      setselectedSecondaryCategoryDropdown(secondaryCategories[selectedPrimaryCategory]);
    } else {
      secondaryCategoryDropdown(selectedPrimaryCategory,token,(res) => {
         setselectedSecondaryCategoryDropdown(res);
         secondaryCategories[selectedPrimaryCategory] = res;
         Storage.set("secondaryCategories", secondaryCategories);
       },
       (e) => (e));
    }
  };

  useEffect(() => {
    if (
      selectedSecondaryCategoryDropdown &&
      selectedPrimaryCategory &&
      selectedSecondaryCategoryDropdown
    ) {
      FindSecondaryFDCId(selectedSecondaryCategoryDropdown);
    }
  }, [
    selectedSecondaryCategoryDropdown,
    selectedPrimaryCategory,
  ]);
  const FindSecondaryFDCId = (data) => {
    var beverage = data.find((obj) => {
      return obj.name === "BEVERAGE CUPS";
    });

    setBeverageCupId(beverage?.id);
    var FDC = data.find((obj) => {
      return obj.name === "FOOD CONTAINERS & LIDS";
    });

    setFDCId(FDC?.id);
  };

  const getSecondarySubCategoryDropdown = () => {
    axios
      .get(
        `${Config.apiBaseAddress}/api/category/secondarysub/${selectedSecondaryCategory}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
            "Access-control-allow-origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setselectedSecondarySubCategoryDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getSubCategoryDropdown = () => {
    let subCategories = Storage.get("subCategories");
    if (subCategories[selectedSecondaryCategory]) {
      setselectedSubCategoryDropdown(subCategories[selectedSecondaryCategory]);
    } else {
      subCategoryDropdown(selectedSecondaryCategory,token,(res)=> {
        setselectedSubCategoryDropdown(res);
        subCategories[selectedSecondaryCategory] = res;
        Storage.set("subCategories", subCategories);
      },
      (e) => {});
    }
  };

  const getCompostableDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/compostable`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedCompostableDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getReusableDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/reusableAndRefillable`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedReusableDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getProductTypeItemLidDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/productTypeItemOrLid`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setselectedProductTypeLidDropdown(res?.data);
      })

      .catch((e) => {});
  };

  useEffect(() => {
    if (selectedProductTypeLidDropdown) {
      FindProductTypeLidId(selectedProductTypeLidDropdown);
    }
  }, [selectedProductTypeLidDropdown]);
  const FindProductTypeLidId = (data) => {
    var lid = data.find((obj) => {
      return obj.name === "Lid";
    });

    setLid(lid.id);

    var lidContainer = data.find((obj) => {
      return obj.name === "Container/Lid Combo";
    });

    setLidContainer(lidContainer.id);

    var lidCup = data.find((obj) => {
      return obj.name === "Cup/Lid Combo";
    });
    setLidCup(lidCup.id);
  };

  const compostable = [
    { name: "Certified Not Compostable", code: 1 },
    { name: "Certified Compostable-Industrial", code: 2 },
    { name: "Certified Compostable-Home and Industrial", code: 3 },
  ];
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

    useEffect(() => {
        getPrimaryCategoryDropdown();
        getMaterialTypeDropdown();
        getMaterialTypeLidDropdown();
    }, []);

  useEffect(() => {
    if (supplierId !== null) {
      getItemList();
    } else {
      setProducts(null);
    }
  }, [JSON.stringify(supplierId), lazyState]);
  useEffect(() => {
    if (selectedMaterialType) {
      getMaterialSubTypeDropdown();
  }
  }, [selectedMaterialType]);

    useEffect(() => {
        if (selectedMaterialTypeLid) {
            getMaterialSubTypeLidDropdown();
        }
    }, [selectedMaterialTypeLid]);

    useEffect(() => {
        if (!selectedPrimaryCategory) {
            setselectedSecondaryCategory(null);
        }
        if (selectedPrimaryCategory) {
            getSecondaryCategoryDropdown();
        }
    }, [selectedPrimaryCategory]);

    useEffect(() => {
        if (selectedSecondaryCategory) {
            getSecondarySubCategoryDropdown();
        }
    }, [selectedSecondaryCategory]);

    useEffect(() => {
        if (selectedSecondaryCategory) {
            getSubCategoryDropdown();
        }
    }, [selectedSecondaryCategory]);


  const getItemList = () => {
    setIsLoading(true);
    let callback;
    let body = JSON.parse(JSON.stringify(lazyState));
    if (isEnricher(Role)) {
      callback = itemList;
    } else if (isSupplier(Role)) {
      callback = supplierItemList;
      //body.SupplierIds = supplierId?.map((data, k) => data?.id);
      if (Array.isArray(supplierId)) {
        body.SupplierIds = supplierId.map((data) => data?.id);
    } else {
        body.SupplierIds = [];  // Fallback in case it's not an array
    }
      body.ManufacturerIds = props.selectedManufacturers;
    }
    callback(body, token, 
    (res) => {
      setTotalRecords(res?.data?.TotalRecords);
      setProducts(res?.data?.Items);
      setIsLoading(false);
      
    }, 
    async (e) => {       
      if (e.response?.status == 401) {
        showErrorToastNullable(toast, "Session Timeout! Please Login again");

        await sleep(5000);
        deleteStoredData();
        navigate("/");
      }
      showErrorToastNullable(toast, e.response?.data?.Message);
      setIsLoading(false);
    });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    setSubmitted(false);
    setProductDialog(true);
  };

  const handleOnChangeFile = (e) => {
    setError("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      setFile(inputFile);
    }
  };
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmitFile = (e) => {
    e.preventDefault();
    if (!file) {
      return setError("Enter a valid file");
    } else {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  // onchange event
  const fileObj = ["application/pdf"];
  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileObj.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setSelectPdfFile(e.target.result);
          setPdfFileError("");
        };
      } else {
        setSelectPdfFile(null);
        setPdfFileError("Please select valid pdf file");
      }
    } else {
      alert("select pdf file");
    }
  };

  // form submit
  const handleSubmitFile = (e) => {
    e.preventDefault();
    if (selectPdfFile !== null) {
      setViewPdf(selectPdfFile);
      showSuccessToast(toast, "File Uploaded");
    } else {
      setViewPdf(null);
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setImportDialog(false);
  };

  const errHideDialog = () => {
    setErrorDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const onHideDeactivationDialog = () => {
    setShowDeactivateDialog(false);
    setDeactivationComments("");
  }
  const confirmDeactivateItem = () => {
    let body = {
      Id: product.Id,
      ItemNumber: product.ItemNumber,
      Comment: deactivationComments
    };
    DeactivateItem(body, token, 
      (res) => {
        if (res.status === 200) {
          showSuccessToast(toast, res?.statusText);
          getItemList();
          setProductDialog(false);
        } else if (res.status === 401) {
          showWarningToast(toast, res?.statusText);
        } else {
          showErrorToast(toast, res?.statusText);
        }
      },
      (e) => {
        showErrorToast(toast, e.response?.data?.Message, 6000);
      }
    );
    onHideDeactivationDialog();
  };

  const saveProduct = () => {
    let emptyProduct = {
      Id: product.Id,
      Name: product.Name,
      ItemNumber: product.ItemNumber,
      ItemDescription: product.ItemDescription,
      NetProductWeight: product.NetProductWeight,
      MaterialTypeId: selectedMaterialType,
      MaterialSubTypeId: selectedMaterialSubType,
      MaterialTypeLidId: selectedMaterialTypeLid,
      MaterialSubTypelidId: selectedMaterialSubTypeLid,
      PrimaryCategoryId: selectedPrimaryCategory,
      SecondaryCategoryId: selectedSecondaryCategory,
      SecondarySubCategoryId: selectedSecondarySubCategory,
      SubCategoryId: selectedSubCategory,
      BrandName: product.BrandName,
      SubBrandName: product.SubBrandName,
      CustomPrinted: selectedCustomPrinted,
      CustomerExclusive: selectedCustomerExclusive,
      Customer: product.Customer,
      CountryId: selectedCountry,
      VendorPartNumber: product.VendorPartNumber,
      GTIN: product.GTIN,
      CompostableId: selectedCompostable,
      Renewable: product.Renewable,
      ReusableAndRefillableId: selectedReusable,
      PostConsumerRecyleContent: product.PostConsumerRecyleContent,
      PreConsumerRecyleContent: product.PreConsumerRecyleContent,
      IncludesPFAS: selectedIncludePFA,
      IncludesPthalates: selectedIncludePthalates,
      ColorId: selectedColors,
      OutreachVendorNumber: product.OutreachVendorNumber,
      OutreachVendorName: product.OutreachVendorName,
      OutreachVendorContactName: product.OutreachVendorContactName,
      OutreachVendorContactEmail: product.OutreachVendorContactEmail,
      ProductUseByConsumer: selectedProductConsumer,
      ProductType: product.ProductType,
      ProductTypeItemOrLid: selectedProductTypeLid,
      PlasticFilmThinkness: product.PlasticFilmThinkness,
      PlasticFilmEmbossed: selectedPlasticFilmEmbossed,
      CoatedPaper: selectedCoatedPaper,
      PlasticFilmThinknessPrePostEmbossing: selectedPlasticFilmPrePostEmbossed,
      UnitOfMeasureId: product.UnitOfMeasureId,
      BuyPackDescription: product.BuyPackDescription,
      GrossProductWeight: product.GrossProductWeight,
      PrimaryCategory: product.PrimaryCategory,
      SecondaryCategory: product.SecondaryCategory,
      SubCategory: product.SubCategory,
      BuyUnitOfMeasure: product.BuyUnitOfMeasure,
      PackDescription: product.PackDescription,
      SupplierId: product.SupplierId,
      StatusId: product.StatusId,
      Status: product.Status,
      MaterialType: selectedMaterialType,
      UserId: product.UserId,
      CreatedBy: product.CreatedBy,
      ...(!isSupplier(Role) && {
        ItemEnrichmentDetails: {
          ItemId: product.Id,
          IsFoundOnline: isFoundOnline,
          Website: websiteAddress,
          Notes: notes,
        }
      }),
        TempHfmCode : {
        HFM47Code: selectedHFMExternalCode||selectedHFMInternalCode,
        HFM47SubCode:"",
        HFM47Notes:"",
        ItemId:product.Id,
        IsActive: true,
    },
      HfmInternalCode: selectedHFMInternalCode,
      HfmExternalCode: selectedHFMExternalCode,
    };
    setSubmitted(true);
     saveProducts(emptyProduct,token,(res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, resdata?.statusText);
          getItemList();
          viewDetail(product.Id);
          setProductDialog(false);
          setErrorDialog(false);
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {
        setErrorDialog(true);
        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);

          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
  };
  const saveProductUpdate = () => {
    let emptyProduct = {
      Id: product.Id,
      Name: product.Name,
      ItemNumber: product.ItemNumber,
      ItemDescription: product.ItemDescription,
      NetProductWeight: product.NetProductWeight,
      MaterialTypeId: selectedMaterialType,
      MaterialSubTypeId: selectedMaterialSubType,
      MaterialTypeLidId: selectedMaterialTypeLid,
      MaterialSubTypelidId: selectedMaterialSubTypeLid,
      PrimaryCategoryId: selectedPrimaryCategory,
      SecondaryCategoryId: selectedSecondaryCategory,
      SecondarySubCategoryId: selectedSecondarySubCategory,
      SubCategoryId: selectedSubCategory,
      BrandName: product.BrandName,
      SubBrandName: product.SubBrandName,
      CustomPrinted: selectedCustomPrinted,
      CustomerExclusive: selectedCustomerExclusive,
      Customer: product.Customer,
      CountryId: selectedCountry,
      VendorPartNumber: product.VendorPartNumber,
      GTIN: product.GTIN,
      CompostableId: selectedCompostable,
      Renewable: product.Renewable,
      ReusableAndRefillableId: selectedReusable,
      PostConsumerRecyleContent: product.PostConsumerRecyleContent,
      PreConsumerRecyleContent: product.PreConsumerRecyleContent,
      IncludesPFAS: selectedIncludePFA,
      IncludesPthalates: selectedIncludePthalates,
      ColorId: selectedColors,
      OutreachVendorNumber: product.OutreachVendorNumber,
      OutreachVendorName: product.OutreachVendorName,
      OutreachVendorContactName: product.OutreachVendorContactName,
      OutreachVendorContactEmail: product.OutreachVendorContactEmail,
      ProductUseByConsumer: selectedProductConsumer,
      ProductType: product.ProductType,
      ProductTypeItemOrLid: selectedProductTypeLid,
      PlasticFilmThinkness: product.PlasticFilmThinkness,
      PlasticFilmEmbossed: selectedPlasticFilmEmbossed,
      CoatedPaper: selectedCoatedPaper,
      PlasticFilmThinknessPrePostEmbossing: selectedPlasticFilmPrePostEmbossed,
      UnitOfMeasureId: product.UnitOfMeasureId,
      BuyPackDescription: product.BuyPackDescription,
      GrossProductWeight: product.GrossProductWeight,
      PrimaryCategory: product.PrimaryCategory,
      SecondaryCategory: product.SecondaryCategory,
      SubCategory: product.SubCategory,
      BuyUnitOfMeasure: product.BuyUnitOfMeasure,
      PackDescription: product.PackDescription,
      SupplierId: product.SupplierId,
      StatusId: product.StatusId,
      Status: product.Status,
      MaterialType: selectedMaterialType,
      UserId: product.UserId,
      CreatedBy: product.CreatedBy,
      ...(!isSupplier(Role) && {
        ItemEnrichmentDetails: {
          ItemId: product.Id,
          IsFoundOnline: isFoundOnline,
          Website: websiteAddress,
          Notes: notes,
        }
      }),
      TempHfmCode : {
        HFM47Code: selectedHFMExternalCode || selectedHFMInternalCode,
        HFM47SubCode:"",
        HFM47Notes:"",
        ItemId:product.Id,
        IsActive: true,
    },
      HfmInternalCode: selectedHFMInternalCode,
      HfmExternalCode: selectedHFMExternalCode,
    };

    setSubmitted(true);
     saveProductsUpdate(emptyProduct,token,(res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Saved Successfully");
          getItemList();
          viewDetail(product.Id);
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {
        setErrorDialog(true);
        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);
          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
  };
  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const viewDetail = async (itemId) => {
      viewDetails(itemId,token,(res)=> {
        setProduct(res?.data);
        setCount(count + 1);
      },
       (e) => {
        showErrorToast(toast, e.response?.statusText);
      });
    setProductDialog(true);
  };

  useEffect(() => {
    if (product) {
      let product_data = product?.MaterialTypeId;
      setselectedMaterialType(product_data);
      setselectedMaterialSubType(product?.MaterialSubTypeId);
      setselectedMaterialTypeLid(product?.MaterialTypeLidId);
      setselectedMaterialSubTypeLid(product?.MaterialSubTypeLidId);
      setselectedPrimaryCategory(product?.PrimaryCategoryId);
      setselectedSecondaryCategory(product?.SecondaryCategoryId);
      setselectedSecondarySubCategory(product?.SecondarySubCategoryId);
      setselectedSubCategory(product?.SubCategoryId);
      setselectedCoatedPaper(product?.CoatedPaper);
      setselectedCustomPrinted(product?.CustomPrinted);
      setselectedCustomerExclusives(product?.CustomerExclusive);
      setselectedIncludePFA(product?.IncludesPFAS);
      setselectedIncludePthalates(product?.IncludesPthalates);
      setselectedPlasticFilmEmbossed(product?.PlasticFilmEmbossed);
      setselectedPlasticFilmPrePostEmbossed(
        product?.PlasticFilmThinknessPrePostEmbossing
      );
      setselectedProductConsumer(product?.ProductUseByConsumer);
      setselectedProductTypeLid(product?.ProductTypeItemOrLid);
      setSelectedCompostable(product?.CompostableId);
      setSelectedColors(product?.ColorId);
      setSelectedCountry(product?.CountryId);
      setSelectedReusable(product?.ReusableAndRefillableId);
      setIsFoundOnline(product?.ItemEnrichmentDetails?.IsFoundOnline);
      setWebsiteAddress(product?.ItemEnrichmentDetails?.Website);
      setselectedHFMInternalCode(product?.HfmInternalCode);
      setselectedHFMExternalCode(product?.HfmExternalCode);
      setNotes(product?.ItemEnrichmentDetails?.Notes);
    }
  }, [count]);

  useEffect(() => {
    if (!isFoundOnline) {
      setWebsiteAddress('');
    }
  }, [isFoundOnline]);

  const importFileDialog = () => {
    setImportDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    let _products = products.filter((val) => val.id !== product.id);

    setProducts(_products);
    setDeleteProductDialog(false);
    showSuccessToast(toast, "Product Deleted");
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const importCSV = (e) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      const data = csv.split("\n");

      // Prepare DataTable
      const cols = data[0].replace(/['"]+/g, "").split(",");
      data.shift();

      const importedData = data.map((d) => {
        d = d.split(",");
        const processedData = cols.reduce((obj, c, i) => {
          c =
            c === "Status"
              ? "inventoryStatus"
              : c === "Reviews"
              ? "rating"
              : c.toLowerCase();
          obj[c] = d[i].replace(/['"]+/g, "");
          (c === "price" || c === "rating") && (obj[c] = parseFloat(obj[c]));
          return obj;
        }, {});

        processedData["id"] = createId();
        return processedData;
      });

      const _products = [...products, ...importedData];

      setProducts(_products);
    };

    reader.readAsText(file, "UTF-8");
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const statusFilterOptions = [
    { text: "All", value: [] },
    { text: Status.PENDING, value: [Status.PENDING, Status.INTERNALLY_ENRICHED, Status.INTERNALLY_REVIEWED, Status.EXTERNALLY_ENRICHED, Status.EXTERNALLY_REVIEWED],  },
    { text: Status.IN_PROGRESS, value: [Status.IN_PROGRESS] }
  ];

  const onStatusFilterChange = (e) => {
    setStatusFilterValue(e.value);
    let value = e.value;
    setlazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "Status.Status").value = value;
      return state;
    });
  };

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const onGlobalFilterEnter = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [value];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };
  const onGlobalFilterBlur = (e) => {
    const value = e.target.value;
    const prevGlobalFilterValue = lazyState.filters.find(f => f.type === "global").value[0];
    if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    showSuccessToast(toast, "Products Deleted");
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };

    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;
    if (name === "OutreachVendorContactEmail") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }

    if (name === "PlasticFilmThinkness") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidPlasticFilmThinkness(true);
      } else {
        setIsValidPlasticFilmThinkness(false);
      }
    }

    if (name === "NetProductWeight") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidNetProductWeight(true);
      } else {
        setIsValidNetProductWeight(false);
      }
    }

    if (name === "GrossProductWeight") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidGrossProductWeight(true);
      } else {
        setIsValidGrossProductWeight(false);
      }
    }

    if (name === "VendorPartNumber") {
      if (!/^[a-z0-9]+$/i.test(e.target.value)) {
        setIsValidSupplierPartNumber(true);
      } else {
        setIsValidSupplierPartNumber(false);
      }
    }

    setProduct(_product);
  };
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const importFile = (e) => {
    return (
      <div>
        <ImportSupplier />
      </div>
    );
  };

  const rejectFunc = () => {
    showWarningToast(toast, "You have rejected", 3000, "Rejected");
  };

  const confirm = () => {
    confirmDialog({
      message:
        "Please confirm your changes before submitting for further review. Do you want to submit this form?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => saveProduct(),
      reject: () => rejectFunc(),
      className: "dialogWidth",
    });
  };

  const activityBodyTemplate = (rowData) => {
    return (
      !isStatusInProgress(rowData?.Status) ?
      <ProgressBar
        value={Math.round(rowData.PercentageComplete)}
        pt={{
          value: {
            style:
              rowData.PercentageComplete >= 26 &&
              rowData.PercentageComplete < 100
                ? { background: "orange" }
                : rowData.PercentageComplete <= 25
                ? { background: "red" }
                : { background: "green" },
          },
        }}
        showValue={false}
        style={{ height: "8px" }}
      ></ProgressBar>
   :  <ProgressBar
   value={Math.round(rowData.PercentageComplete)}
   pt={{
     value: {
       style:
           { background: "orange" }
     },
   }}
   showValue={false}
   style={{ height: "8px" }}
 ></ProgressBar> 

    );
  };

  const viewDetailTemplate = (data) => {
    return (<Button
      label="View Details"
      style={{ backgroundColor: "#003e7e" }}
      severity="success"
      onClick={() => viewDetail(data.Id)}
    />);
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <div className="flex flex-wrap gap-2 ">
          <Button
            label="Import"
            icon="pi pi-upload"
            className="button-color "
            style={{ backgroundColor: "#003e7e" }}
            onClick={importFileDialog}
          />

          <Button
            label="Export"
            icon="pi pi-download"
            className="p-button-help"
            style={{ backgroundColor: "#003e7e" }}
            onClick={exportCSV}
          />
        </div> */}
        {/* <div>
          <label>
            <b>Vendor Contact Name</b> : {products && products[0]?.OutreachVendorContactName}
            <b>, Vendor Contact Email</b> : {products && products[0]?.OutreachVendorContactEmail}
          </label>
        </div> */}
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
        alt={rowData.image}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.inventoryStatus}
        severity={getSeverity(rowData)}
      ></Tag>
    );
  };

  const actionBodyTemplate = (rowData) => {
    setRowData(rowData);
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const getSeverity = (product) => {
    switch (product.activity) {
      case product.activity === 100:
        return "success";

      case product.activity >= 26:
        return "warning";

      case product.activity <= 25:
        return "danger";

      default:
        return null;
    }
  };
  const PrimaryCategoryChange = (e) => {
    setselectedPrimaryCategory(e.value);
    setselectedSecondaryCategory(null);
    // setselectedSecondarySubCategory(null)
    setselectedSubCategory(null);
  };

  const SecondaryCategoryChange = (e) => {
    setselectedSecondaryCategory(e.value);
    // setselectedSecondarySubCategory(null)
    setselectedSubCategory(null);
    setselectedProductTypeLid(null);
  };

  const ProductTypeItemLidChange = (e) => {
    setselectedProductTypeLid(e.value);
    // setselectedSecondarySubCategory(null)
    setselectedMaterialTypeLid(null);
    setselectedMaterialSubTypeLid(null);
  };

  // const SecondarySubCategoryChange=(e)=>{
  //   setselectedSecondarySubCategory(e.value)
  //   setselectedSubCategory(null)
  // }

  const MaterialTypeChange = (e) => {
    setselectedMaterialType(e.value);
    setselectedMaterialSubType(null);
  };

  const MaterialTypeLidChange = (e) => {
    setselectedMaterialTypeLid(e.value);
    setselectedMaterialSubTypeLid(null);
  };

  const onIsFoundOnlineRadio =(e) =>
    {
       setIsFoundOnline(e.value);
    };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">ITEM PAGE </h4>
        <div className="flex flex-wrap gap-2">
          <Dropdown 
            value={statusFilterValue}
            options={statusFilterOptions}
            onChange={onStatusFilterChange}
            optionLabel="text"
            optionValue="value"
          />

          {/* <SelectButton value={setGlobalFilter("Accessories")} label='ALL' /> */}
          <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onInput={onGlobalFilterChange}
            placeholder="Search..."
            onKeyPress={onGlobalFilterEnter}
            onBlur={onGlobalFilterBlur}
          />
          </span>
      </div>
    </div>
  );

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        style={{ backgroundColor: "#003e7e" }}
        icon="pi pi-times"
        outlined
        onClick={hideDialog}
      />
      <Button
        label="Save"
        style={{ backgroundColor: "#003e7e" }}
        icon="pi pi-check"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const tabCardFooter = (
    <React.Fragment>
      <Button
        style={{ width: "10rem", marginLeft: 5, backgroundColor: "#003e7e" }}
        label="Save"
        // disabled={product?.Status?.Status === Approved }
        icon="pi pi-check"
        onClick={saveProductUpdate}
      />
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const tabSubmit = (
    <React.Fragment>
      <Button
        style={{ width: "10rem", backgroundColor: "#003e7e" }}
        label="Deactivate"
        icon="pi pi-trash"
        onClick={() => setShowDeactivateDialog(true)}
      />
      <Button
        style={{ width: "10rem", backgroundColor: "#003e7e" }}
        label="Cancel"
        icon="pi pi-times"
        onClick={hideDialog}
      />
      {/* <Button label="Submit" disabled={(!product.OutreachVendorContactName||!product.OutreachVendorContactEmail||!selectedProductConsumer||!product.ProductType||!selectedProductTypeLid||!product.PlasticFilmThinkness||!selectedPlasticFilmEmbossed||!selectedPlasticFilmPrePostEmbossed||!selectedCoatedPaper||!selectedMaterialType||!selectedCompostable||!selectedCustomPrinted||
          !selectedCustomerExclusive||!selectedCountry||!product.NetProductWeight||!selectedCompostable||!selectedReusable||!selectedIncludePFA||!selectedIncludePthalates||!product.PostConsumerRecyleContent||!product.PreConsumerRecyleContent||!product.Renewable)? true : false}  style={{backgroundColor:'#003e7e'}} icon="pi pi-check" onClick={confirm} /> */}
      <Button
        label="Submit"
        disabled={
          isStatusApproved(product?.Status) ||
          (isFoundOnline === true && !websiteAddress) ||
          (isFoundOnline == null && !isSupplier(Role)) ||
          (
            (!product?.ProductType ||
            !product?.VendorPartNumber ||
            !selectedMaterialType ||
            !selectedMaterialSubType ||
            !selectedPrimaryCategory ||
            !selectedSecondaryCategory ||
            !selectedSubCategory ||
            // ((selectedMaterialType === PaperId ||
            //   selectedMaterialType === PaperboardId ||
            //   selectedMaterialType === Paperboard_BagasseId ||
            //   selectedMaterialType === Paperboard_Molded_PulpId) && selectedCoatedPaper == null) ||
            selectedIncludePFA == null ||
            (isExternalEnricher(Role) && !selectedHFMExternalCode) ||
            (isInternalEnricher(Role) && !selectedHFMInternalCode) ||
            (isSupplier(Role) &&
              (
                (selectedMaterialType === PlasticFilmId && !product?.PlasticFilmThinkness) ||
                (selectedMaterialType === PlasticFilmId && selectedPlasticFilmPrePostEmbossed == null) ||
                (selectedMaterialType === PlasticFilmId && selectedPlasticFilmEmbossed == null)
              )
            )
          ) &&
            (isSupplier(Role) || isFoundOnline === true)
          )
        }
        style={{ backgroundColor: "#003e7e" }}
        icon="pi pi-check"
        onClick={confirm}
      />
      {/* <Button label="Submit"   style={{backgroundColor:'#003e7e'}} icon="pi pi-check" onClick={confirm} /> */}

      {/* {isdisabled && <small>Fill in the required fields</small>} */}
      <Dialog
        header="Deactivate Item"
        visible={showDeactivateDialog}
        onHide={onHideDeactivationDialog}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={onHideDeactivationDialog}
            />
            <Button
              label="Confirm"
              className="p-button-danger"
              onClick={confirmDeactivateItem}
              disabled={!deactivationComments}
            />
          </div>
        }
      >
        <div className="deactivationComments">
          <label htmlFor="comments" className="font-bold">
            Comments <span style={{ color: "red" }}>*</span>
          </label>
            <InputTextarea
              id="comments"
              value={deactivationComments || ""}
              onChange={(e) => setDeactivationComments(e.target.value)}
              placeholder="Please enter comments here" 
              required
            />
          {!deactivationComments && (
            <small className="p-error">Comments are required.</small>
          )}
        </div>
        <p>Are you sure you want to proceed?</p>
      </Dialog>
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div>
      <Toast ref={toast} />
      {/* <Toast ref={toastBC} position="top-right" /> */}

      <ConfirmDialog />

      {/* <ImportSupplier/>    */}
      <div className="card">

        <ItemsDataTable Role={Role} 
          products={products} 
          header={header} 
          dt={dt}
          viewDetailTemplate={viewDetailTemplate}
          activityBodyTemplate={activityBodyTemplate}
          loading={isLoading}
          lazyState={lazyState}
          setlazyState={setlazyState}
          totalRecords={totalRecords}
        />
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "55rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Item Details"
        modal
        className="p-fluid"
        footer={tabSubmit}
        onHide={hideDialog}
        maximizable
      >
        <div>
          <label>
            <b>Item Number</b> : {product && product?.ItemNumber}
          </label>
        </div>
        <div>
          <label>
            <b>Item Description</b> : {product && product?.ItemDescription}
          </label>
        </div>
        <div>
          <label>
            <b>Supplier Part Number</b> : {product && product?.VendorPartNumber}
          </label>
        </div>
        <div>
          <label>
            <b>Supplier Name</b> : {product && product?.Supplier?.Name}
          </label>
        </div>
        <div>
          {product?.Manufacturer && (
            <label>
              <b>Manufacturer Name</b> : {product?.Manufacturer.ManufacturerName}
            </label>
          )}
        </div>
        <div>
          <label>
            <b>Status</b> : {product && product?.Status?.Status || ""}
          </label>
        </div>
        
        <Card footer={tabCardFooter}>
          <TabView>
            <TabPanel header="Product Data">
              <div className="formgrid grid gap-6">
              {isEnricher(Role) &&(<><div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                  Item Found Online? <span style={{ color: "red" }}>*</span>
                    <span >
                  <Tooltip target=".custom-target-iconIFO" style={{width:'15rem'}} >
                  If product specifications are available publicly on web, 
                  please share link where found.              
                  </Tooltip>
                  <i className="custom-target-iconIFO  pi pi-info-circle"
                   data-pr-position="top"
                   style={{color:'black'}}
                    />
                    </span>
                  </label>
                  <div className="flex align-items-center foundOnline-container">
                    <div>
                      <RadioButton name="foundOnline" value={true} onChange={onIsFoundOnlineRadio}
                      checked={isFoundOnline === true}/>
                      <label htmlFor="item1" className="p-radiobutton-label">Yes</label> 
                    </div>
                    <div>
                      <RadioButton name="foundOnline" value={false} onChange={onIsFoundOnlineRadio}
                       checked={isFoundOnline === false}/>
                      <label htmlFor="item2" className="p-radiobutton-label">No</label> 
                   </div>
                  </div>
                </div>
             
               <div className="col-md-5">
                  <label htmlFor="WebsiteAddress" className="font-bold">
                    Website Address {isFoundOnline && (<span style={{ color: "red" }}>*</span>)}
                  </label>
                    <InputTextarea
                      id="WebsiteAddress"
                      value={websiteAddress || ""}
                      onChange={(e) => setWebsiteAddress(e.target.value)}
                      placeholder="Copy and paste Url where item is found" 
                      required={isFoundOnline}
                      disabled={!isFoundOnline}
                      style={{ flex: 1 }}
                    />
                    {websiteAddress && (
                  <a href={websiteAddress} target="_blank" rel="noopener noreferrer"
                  ></a>)}
                  {isFoundOnline && !websiteAddress && (
                    <small className="p-error">Website Address is required.</small>
                  )}
                </div>
                </>)}
                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                    {/* Primary Category <span style={{ color: "red" }}>*</span> */}
                    Primary Category {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span>
                      <Tooltip
                        target=".custom-target-iconPrimary"
                        style={{ width: "15rem" }}
                      >
                        Please select the primary category that best describes
                        what the product is.
                      </Tooltip>

                      <i
                        className="custom-target-iconPrimary  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedPrimaryCategory}
                      onChange={(e) => PrimaryCategoryChange(e)}
                      options={selectedPrimaryCategoryDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Primary Category"
                      showClear
                      required={isSupplier(Role) || isFoundOnline}
                      onBlur={() => setIsDisabled(true)}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                    />
                    {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedPrimaryCategory &&
                    typeof selectedPrimaryCategory !== "number" ? (
                      <small className="p-error">
                        Primary Category is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                    {/* Secondary Category <span style={{ color: "red" }}>*</span> */}
                    Secondary Category {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span>
                      <Tooltip
                        target=".custom-target-iconSecondary"
                        style={{ width: "15rem" }}
                      >
                        Please select the secondary category that best describes
                        additional attributes about the product.
                      </Tooltip>

                      <i
                        className="custom-target-iconSecondary  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedSecondaryCategory}
                      onChange={(e) => SecondaryCategoryChange(e)}
                      options={selectedSecondaryCategoryDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Secondary Category"
                      showClear
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      onBlur={() => setIsDisabled(true)}
                    />
                    {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedSecondaryCategory &&
                    typeof selectedSecondaryCategory !== "number" ? (
                      <small className="p-error">
                        Secondary Category is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                    {/* Sub Category <span style={{ color: "red" }}>*</span> */}
                    Sub Category {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span>
                      <Tooltip
                        target=".custom-target-iconSub"
                        style={{ width: "15rem" }}
                      >
                        Please select the sub category that best describes
                        additional attributes about the product.{" "}
                      </Tooltip>

                      <i
                        className="custom-target-iconSub  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedSubCategory}
                      onChange={(e) => setselectedSubCategory(e.value)}
                      options={selectedSubCategoryDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Sub Category"
                      showClear
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      onBlur={() => setIsDisabled(true)}
                    />
                    {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedSubCategory &&
                    typeof selectedSubCategory !== "number" ? (
                      <small className="p-error">
                        Sub Category is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                    {/* Material Type <span style={{ color: "red" }}>*</span> */}
                    Material Type {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span>
                      <Tooltip
                        target=".custom-target-iconMT"
                        style={{ width: "15rem" }}
                      >
                        Please select the dominant raw material (by weight) of
                        the main product component. Examples: Select
                        "Paperboard" for a paperboard box with a plastic window;
                        Select "Metal" for an aluminum foil contained in a
                        paperboard box.
                      </Tooltip>

                      <i
                        className="custom-target-iconMT  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedMaterialType}
                      onChange={(e) => MaterialTypeChange(e)}
                      options={selectedMaterialTypeDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Material Type"
                      showClear
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      onBlur={() => setIsDisabled(true)}
                    />
                    {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedMaterialType &&
                    typeof selectedMaterialType !== "number" ? (
                      <small className="p-error">
                        Material Type is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                    {/* Material Sub Type <span style={{ color: "red" }}>*</span> */}
                    Material Sub Type {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span>
                      <Tooltip
                        target=".custom-target-iconMTS"
                        style={{ width: "15rem" }}
                      >
                        Please select the specific substrate of the main product
                        component (by weight).
                      </Tooltip>

                      <i
                        className="custom-target-iconMTS  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedMaterialSubType}
                      onChange={(e) => setselectedMaterialSubType(e.value)}
                      options={selectedMaterialSubTypeDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Material Sub Type"
                      showClear
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      onBlur={() => setIsDisabled(true)}
                    />
                    {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedMaterialSubType &&
                    typeof selectedMaterialSubType !== "number" ? (
                      <small className="p-error">
                        Material Sub Type is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

               
                <div className="col-md-5">
                  <label htmlFor="ProductType" className="font-bold">
                    {/* Product Type (Open Text)
                    <span style={{ color: "red" }}>*</span> */}
                    Product Type (Open Text) {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                  
                  <span >
                    <Tooltip
                      target=".custom-target-icon5"
                      style={{ width: "15rem" }}
                    >
                      In a few words, please describe what this product is.
                      Examples: Produce Bag, Takeout Food Bowl, Can Liner.
                    </Tooltip>

                    <i
                      className="custom-target-icon5  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                    </span>
                    </label>
                    <InputText
                      id="ProductType"
                      value={product && product?.ProductType || ""}
                      onChange={(e) => onInputChange(e, "ProductType")}
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      tooltip="In a few words, please describe what this product is. 
                    Examples: Produce Bag, Takeout Food Bowl, Can Liner."
                      tooltipOptions={{
                        position: "bottom",
                        className: "custom-tooltip",
                      }}
                      onBlur={() => setIsDisabled(true)}
                    />
                  
                  {(isSupplier(Role) || isFoundOnline) && isdisabled && !product?.ProductType && (
                    <small className="p-error">Product Type is required.</small>
                  )}
                </div>
                {isSupplier(Role) && (
                <div className="col-md-5">
                  <label htmlFor="VendorPartNumber" className="font-bold">
                    {/* Supplier Part Number <span style={{ color: "red" }}>*</span> */}
                    Supplier Part Number {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                  
                  <span >
                    <Tooltip
                      target=".custom-target-icon3"
                      style={{ width: "15rem" }}
                    >
                      The item number assigned by the manufacturer of the
                      product.
                    </Tooltip>

                    <i
                      className="custom-target-icon3  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                    </span>
                    </label>
                    <InputText
                      id="VendorPartNumber"
                      required={isSupplier(Role) || isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      value={product && product?.VendorPartNumber}
                      onChange={(e) => onInputChange(e, "VendorPartNumber")}
                      onBlur={() => setIsDisabled(true)}
                    />
                  
                  {(isSupplier(Role) || isFoundOnline) && isdisabled && !product.VendorPartNumber && (
                    <small className="p-error">
                      Supplier Part Number is required.
                    </small>
                  )}
                  {(isSupplier(Role) || isFoundOnline) && isvalidSupplierPartNumber && (
                    <small className="p-error">
                      Enter only Alphanumeric value
                    </small>
                  )}
                </div>
                )}
                {(isSupplier(Role) || isExternalEnricher(Role) || isInternalEnricher(Role)) && (
                 <div className="col-md-5">
                  <label htmlFor="GrossProductWeight" className="font-bold">
                    {/* Gross Product Weight <span style={{ color: "red" }}>*</span> */}
                    Gross Product Weight {(isSupplier(Role) && isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                  
                  <span>
                    <Tooltip
                      target=".custom-target-icon2"
                      style={{ width: "15rem" }}
                    >
                      <ul>
                        <li>
                          Must be gross weight of the product (Including
                          in-transit packaging) in pounds.
                        </li>
                        <li>
                          Gross Product Weight must be greater than Net Product
                          Weight.
                        </li>
                        <li>Value must be numeric.</li>
                      </ul>
                    </Tooltip>

                    <i
                      className="custom-target-icon2  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                    </span>
                    </label>
                    <InputText
                      id="GrossProductWeight"
                      min={0}
                      max={1}
                      value={product && product?.GrossProductWeight}
                      minFractionDigits={2}
                      required={isSupplier(Role) && isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || (isEnricher(Role) && !isFoundOnline)}
                      onChange={(e) => onInputChange(e, "GrossProductWeight")}
                      onBlur={() => setIsDisabled(true)}
                    />
                  
                  {(isSupplier(Role) && isFoundOnline) && isdisabled && !product.GrossProductWeight && (
                    <small className="p-error">
                      Gross Product weight is required.
                    </small>
                  )}
                  {(isSupplier(Role) && isFoundOnline) && isvalidGrossProductWeight && (
                    <small className="p-error">
                      Enter only Numeric value like (1,1.0,2.00 etc)
                    </small>
                  )}
                </div>
                )}
                

                {selectedMaterialType === PlasticFilmId ? (
                  <div className="col-md-5">
                    <label htmlFor="PlasticFilmThinkness" className="font-bold">
                      {/* Plastic Film Thickness(MILS)
                      <span style={{ color: "red" }}>*</span> */}
                      Plastic Film Thickness(MILS) 
                      {(isSupplier(Role) || (isEnricher(Role) && isFoundOnline)) && (
                          <span style={{ color: "red" }}>*</span>
                        )}                    </label>
                    <span className="p-input-icon-right">
                      <Tooltip
                        target=".custom-target-icon6"
                        style={{ width: "15rem" }}
                      >
                        Please enter the thickness of the product in MILS.
                      </Tooltip>

                      <i
                        className="custom-target-icon6  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                      <InputText
                        minFractionDigits={2}
                        id="PlasticFilmThinkness"
                        name="PlasticFilmThinkness"
                        value={product && product?.PlasticFilmThinkness}
                        onChange={(e) =>
                          onInputChange(e, "PlasticFilmThinkness")
                        }
                        required={isSupplier(Role) || isFoundOnline}
                        onBlur={() => setIsDisabled(true)}
                        disabled={isEnricher(Role) && !isFoundOnline}
                      />
                    </span>
                    {isSupplier(Role) && isFoundOnline === true && isdisabled && !product.PlasticFilmThinkness && (
                     <small className="p-error">
                       Plastic Film Thickness is required.
                      </small>
                    )}
                    {isSupplier(Role) && isFoundOnline === true && isvalidPlasticFilmThinkness && (
                      <small className="p-error">
                        Enter only Numeric value like (1, 1.0, 2.00 etc)
                      </small>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {selectedMaterialType === PlasticFilmId ? (
                  <div className="col-md-5">
                    <label htmlFor="PlasticFilmEmbossed" className="font-bold">
                      {/* Plastic Film Embossed YN
                      <span style={{ color: "red" }}>*</span> */}
                      Plastic Film Embossed YN 
                      {(isSupplier(Role) || (isEnricher(Role) && isFoundOnline)) && (
                          <span style={{ color: "red" }}>*</span>
                        )}                      <span>
                        <Tooltip
                          target=".custom-target-iconPFE"
                          style={{ width: "15rem" }}
                        >
                          Is the Plastic Film Embossed?
                        </Tooltip>
                        <i
                          className="custom-target-iconPFE  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black" }}
                        />
                      </span>
                    </label>
                    <div className="card flex justify-content-center">
                      <Dropdown
                        value={selectedPlasticFilmEmbossed}
                        onChange={(e) =>
                          setselectedPlasticFilmEmbossed(e.value)
                        }
                        options={productUseByConsumer}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select Plastic Film Embossed"
                        showClear
                        required={isSupplier(Role) || isFoundOnline}
                        onBlur={() => setIsDisabled(true)}
                        disabled={isEnricher(Role) && !isFoundOnline}
                      />

                      {isSupplier(Role) && isFoundOnline == true &&
                       isdisabled &&
                      !selectedPlasticFilmEmbossed &&
                      typeof selectedPlasticFilmEmbossed !== "number" ? (
                        <small className="p-error">
                          Plastic Film Embossed is required.
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {selectedMaterialType === PlasticFilmId ? (
                  <div className="col-md-6">
                    <label
                      htmlFor="PlasticFilmThicknessPrePost"
                      className="font-bold"
                    >
                      Plastic Film Thickness Pre Post Embossing
                      {(isSupplier(Role) || (isEnricher(Role) && isFoundOnline)) && (
                          <span style={{ color: "red" }}>*</span>
                        )}                      <span>
                        <Tooltip
                          target=".custom-target-iconPFTE"
                          style={{ width: "15rem" }}
                        >
                          Is the entered Plastic Film Thickness measured before
                          or after product embossing?
                        </Tooltip>

                        <i
                          className="custom-target-iconPFTE  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black" }}
                        />
                      </span>
                    </label>
                    <div className="card flex justify-content-center">
                      <Dropdown
                        value={selectedPlasticFilmPrePostEmbossed}
                        onChange={(e) =>
                          setselectedPlasticFilmPrePostEmbossed(e.value)
                        }
                        options={plasticFilmPrePost}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                        showClear
                        required={isSupplier(Role) || isFoundOnline}
                        onBlur={() => setIsDisabled(true)}
                        disabled={isEnricher(Role) && !isFoundOnline}
                      />

                      {isSupplier(Role) && isFoundOnline == true  &&
                       isdisabled &&
                      !selectedPlasticFilmPrePostEmbossed &&
                      typeof selectedPlasticFilmPrePostEmbossed !== "number" ? (
                        <small className="p-error">
                          PlasticFilmPrePostEmbossed is required.
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {selectedMaterialType === PaperId ||
                selectedMaterialType === PaperboardId ||
                selectedMaterialType === Paperboard_BagasseId ||
                selectedMaterialType === Paperboard_Molded_PulpId ? (
                  <div className="col-md-5">
                    <label htmlFor="name" className="font-bold">
                      Coated Paper Or Paperboard
                      {(isSupplier(Role) || (isEnricher(Role) && isFoundOnline)) && (
                          <span style={{ color: "red" }}>*</span>
                        )}                      <span>
                        <Tooltip
                          target=".custom-target-iconCP"
                          style={{ width: "15rem" }}
                        >
                          For fiber products, is this product coated with
                          another material?
                        </Tooltip>

                        <i
                          className="custom-target-iconCP  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black" }}
                        />
                      </span>
                    </label>
                    <div className="card flex justify-content-center">
                      <Dropdown
                        value={selectedCoatedPaper}
                        onChange={(e) => setselectedCoatedPaper(e.value)}
                        options={productUseByConsumer}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select"
                        showClear
                        required={isSupplier(Role) || isFoundOnline}
                        onBlur={() => setIsDisabled(true)}
                        disabled={isEnricher(Role) && !isFoundOnline}
                      />
                      {(isSupplier(Role) || isFoundOnline) &&
                       isdisabled &&
                      !selectedCoatedPaper &&
                      typeof selectedCoatedPaper !== "number" ? (
                        <small className="p-error">
                          Coated Paper is required.
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                  Include PFAS {(isSupplier(Role) || isFoundOnline) && (<span style={{ color: "red" }}>*</span>)}
                    <span >
                  <Tooltip target=".custom-target-iconIP" style={{width:'15rem'}} >
                  Does this product contain intentionally added PFAS?               
                    </Tooltip>

                  <i className="custom-target-iconIP  pi pi-info-circle"
                   data-pr-position="top"
                   style={{color:'black'}}
                    />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedIncludePFA}
                      onChange={(e) => setselectedIncludePFA(e.value)}
                      options={includesPFASOptions}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a PFAs"
                      required={isSupplier(Role) || isFoundOnline}
                      showClear
                      disabled={isEnricher(Role) && !isFoundOnline}
                      onBlur={() => setIsDisabled(true)}
                    />
                     {(isSupplier(Role) || isFoundOnline) &&
                     isdisabled &&
                    !selectedIncludePFA &&
                    typeof selectedIncludePFA !== "number" ? (
                      <small className="p-error">
                        Include PFAs is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* <div className="col-md-5"></div> */}
                
                {/*isSupplier(Role) &&(<><div className="col-md-5">
                  <label htmlFor="name" className="font-bold">
                  Item Found Online? <span style={{ color: "red" }}>*</span>
                    <span >
                  <Tooltip target=".custom-target-iconIFO" style={{width:'15rem'}} >
                  If product specifications are available publicly on web, please share link where found.              
                  </Tooltip>
                  <i className="custom-target-iconIFO  pi pi-info-circle"
                   data-pr-position="top"
                   style={{color:'black'}}
                    />
                    </span>
                  </label>
                  <div className="flex align-items-center foundOnline-container">
                    <div>
                      <RadioButton name="foundOnline" value={true} onChange={onIsFoundOnlineRadio}
                      checked={isFoundOnline === true}/>
                      <label htmlFor="item1" className="p-radiobutton-label">Yes</label> 
                    </div>
                    <div>
                      <RadioButton name="foundOnline" value={false} onChange={onIsFoundOnlineRadio}
                       checked={isFoundOnline === false}/>
                      <label htmlFor="item2" className="p-radiobutton-label">No</label> 
                   </div>
                  </div>
                </div>
             
               <div className="col-md-5">
                  <label htmlFor="WebsiteAddress" className="font-bold">
                    Website Address {isFoundOnline && (<span style={{ color: "red" }}>*</span>)}
                  </label>
                    <InputTextarea
                      id="WebsiteAddress"
                      value={websiteAddress || ""}
                      onChange={(e) => setWebsiteAddress(e.target.value)}
                      placeholder="Copy and paste Url where item is found" 
                      required={isFoundOnline}
                      disabled={!isFoundOnline}
                      style={{ flex: 1 }}
                    />
                    {websiteAddress && (
                  <a href={websiteAddress} target="_blank" rel="noopener noreferrer"
                  ></a>)}
                  {isFoundOnline && !websiteAddress && (
                    <small className="p-error">Website Address is required.</small>
                  )}
                </div>
                </>)*/}


              {isInternalEnricher(Role) && (
              
               <div className="col-md-5">
                <label htmlFor="internal" className="font-bold">
                   HFM Internal Code {isFoundOnline && (<span style={{ color: "red" }}>*</span>)}
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                       value={selectedHFMInternalCode}
                       id= "internal"
                       onChange={(e) => setselectedHFMInternalCode(e.value)}
                      options={hfmEnricherCodes}
                      // optionLabel="name"
                      // optionValue="id"
                      placeholder="Select from dropdown"
                      showClear
                      required={isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || !isFoundOnline}
                      onBlur={() => setIsDisabled(true)}
                    />
                    {isFoundOnline && !selectedHFMInternalCode && (<small className="p-error">
                        HFM Internal Code is required.
                      </small>)}
                  </div>
                </div>
               )} 
                
                {isExternalEnricher(Role) && (
              <div className="col-md-5">
                <label htmlFor="External" className="font-bold">
                    HFM External Code {isFoundOnline && (<span style={{ color: "red" }}>*</span>)}
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                       value={selectedHFMExternalCode}
                       id= "external"
                       onChange={(e) => setselectedHFMExternalCode(e.value)}
                      options={hfmEnricherCodes}
                      // optionLabel="name"
                      // optionValue="id"
                      placeholder="Select from dropdown"
                      showClear
                      required={isFoundOnline}
                      disabled={isStatusApproved(product?.Status) || !isFoundOnline }
                      onBlur={() => setIsDisabled(true)}
                    />
                  </div>
                </div>
               )} 

                {/* {(isInternalEnricher(Role) || isExternalEnricher(Role)) && ( */}
                <div className="col-md-5">
                  <label htmlFor="Notes" className="font-bold">
                    Notes 
                  <span style={{ marginLeft: '5px' }}>
                    <Tooltip
                      target=".custom-target-iconNotes"
                      style={{ width: "15rem" }}
                    >
                      If material type selected is OTHER, please list specific material type here. ​
                      If product is now inactive, please indicate as such
                    </Tooltip>

                    <i
                      className="custom-target-iconNotes pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                  </span>
                  </label>
                    <InputText
                      id="Notes"
                      placeholder="Open text for comments"
                      value={notes || ""}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                </div>
                {/* )}  */}
               </div>

            </TabPanel>

            {errorDialog && (
              <TabPanel header="Error Message">
                {errorDialog && (
                  <table>
                    <thead>
                      <tr key={"header"}>
                        {Object.keys(errorData[0])?.map((data) => (
                          <th>{data}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ border: "1px solid black" }}>
                      {errorData.map((item, i) => (
                        <tr key={i}>
                          {Object.values(item).map((val) => (
                            <td
                              style={{
                                borderRight: "1px solid black",
                                paddingRight: "25px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </TabPanel>
            )}
          </TabView>
        </Card>
      </Dialog>
      <Dialog
        visible={importDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Import CSV"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChangeFile}
          />

          <button
            style={{ backgroundColor: "#003e7e", color: "white" }}
            onClick={(e) => {
              handleOnSubmitFile(e);
            }}
          >
            IMPORT CSV
          </button>
        </form>

        <br />

        {error ? (
          error
        ) : (
          <table>
            <thead>
              <tr key={"header"}>
                {headerKeys.map((key) => (
                  <th>{key}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {array.map((item) => (
                <tr key={item.id}>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Dialog>

      

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
