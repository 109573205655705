import { useState, useEffect } from "react";
import Storage from "../utils/storage";
import axios from "axios";
import Config from "./../Config";
import { getAccessToken } from "../utils/http";
import ForwardIcon from "@mui/icons-material/Forward";
import classes from "../styles/ItemStatuses.module.css";
import { isApproverOrViewer, isESG, isExternalEnricher, isInternalEnricher, isQuality, isSupplier } from "../utils/roleValidator";
import {
  isStatusPending,
  isStatusApproved,
  isStatusInReview,
  isStatusInProgress,
  isStatusInternallyEnriched,
  isStatusExternallyEnriched,
  isStatusExternallyReviewed,
  isStatusInternallyReviewed,
  isStatusPendingInternal,
  isStatusPendingExternal,
  isStatusExternallyProgress,
  isStatusInternallyProgress,
  isStatusEsgReview,
  isStatusInternalEsgReview,
  isStatusExternalEsgReview,
  isStatusActiveUsers,
  isStatusSuppliers,
  isStatusSupplierUsers,
  isStatusInReviewEsg,
  isStatusExternalApproved,
  isStatusInternalApproved,
} from "../utils/itemStatusValidator";
 
function ItemStatuses(props) {
  const token = getAccessToken();
  const UserId = Storage.get("user");
  const Role = Storage.get("Role");
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [approvedStatusCount, setApprovedStatusCount] = useState(0);
  const [progressStatusCount, setProgressStatusCount] = useState(0);
  const [reviewStatusCount, setReviewStatusCount] = useState(0);
  const [totalStatusCount, setTotalStatusCount] = useState(0);
  const [internallyStatusCount, setInternallyStatusCount] = useState(0);
  const [externallyStatusCount, setExternallyStatusCount] = useState(0);
  const [externalReviewStatusCount, setExternalReviewStatusCount] = useState(0);
  const [internalReviewStatusCount, setInternalReviewStatusCount] = useState(0);
  const [internalPendingStatusCount,setInternalPendingStatusCount] = useState(0);
  const [externalPendingStatusCount,setExternalPendingStatusCount] = useState(0);
  const [externalApprovedStatusCount,setExternalApprovedStatusCount] = useState(0);
  const [internalApprovedStatusCount,setInternalApprovedStatusCount] = useState(0);
  const [internalProgressStatusCount,setInternalProgressStatusCount] = useState(0);
  const [externalProgressStatusCount,setExternalProgressStatusCount] = useState(0);
  const [InternalreviewEsgStatusCount,setInternalReviewEsgStatusCount] = useState(0);
  const [externalreviewEsgStatusCount,setExternalReviewEsgStatusCount] = useState(0);
  const [supplierreviewEsgStatusCount,setSupplierReviewEsgStatusCount] = useState(0);
  const [activeUserStatusCount,setActiveUserStatusCount] = useState(0);
  const [supplierStatusCount, setSupplierStatusCount] = useState(0);
  const [supplierUserStatusCount, setSupplierUserStatusCount] = useState(0);
  const [materialStausCount,SetMaterialStatusCount] = useState(0);
  const [purposeStatusCount,SetPurposeStatusCount] = useState(0);
  const [transitionStatusCount, SetTransitionStatusCount] = useState(0);
  const [regulationStatusCount, SetRegulationStatusCount] = useState(0);
  const [packingStatusCount, SetPackingStatusCount] = useState(0);
  const [errorStatusCount,setErrorStatusCount] = useState(0);
  const { 
    supplierIds, 
    setTotalUsersWithSupplier, 
    setTotalSuppliersOnboarded,
    setTotalActiveUsersWithSupplier,
    manufacturerIds,
    isManufacturer, 
  } = props;

  const populateHFMStatuses = (res) =>
    {
      SetMaterialStatusCount(res?.data['7810 - Alternative materials'] ?? 0);
      SetPurposeStatusCount(res?.data['7820 - Important purpose'] ?? 0);
      SetTransitionStatusCount(res?.data['7830 - Consumables likely to transition'] ?? 0);
      SetRegulationStatusCount(res?.data['7840 - Consumables facing regulation'] ?? 0);
      SetPackingStatusCount(res?.data['9999 - Non Packaging'] ?? 0);
      setErrorStatusCount(res?.data['8888 - Potentially packaging'] ?? 0);
    }
    const populateUserStatuses = (res) =>{
      setActiveUserStatusCount(res?.data.TotalActiveUsers);
      setSupplierStatusCount(res?.data.TotalOnBoardedSuppliers);
      setSupplierUserStatusCount(res?.data.TotalOnBoardedSupplierUsers);

    }
  
  const populateItemStatuses = (res) =>
  {
    
    if (isApproverOrViewer(Role)) {
      setTotalSuppliersOnboarded(res?.data.SupplierCount);
      setTotalUsersWithSupplier(res?.data.UserCount);
      setTotalActiveUsersWithSupplier(res?.data.ActiveUserCount);
    }
    setTotalStatusCount(res?.data.itemCount);

    const pendingStatus = res?.data?.Status.find(isStatusPending);
    setPendingStatusCount (pendingStatus ? pendingStatus.StatusCount : 0);
    const approveStatus = res?.data?.Status.find(isStatusApproved);
    setApprovedStatusCount(approveStatus ? approveStatus.StatusCount : 0);
    const reviewStatus = res?.data?.Status.find(isStatusInReview);
    setReviewStatusCount(reviewStatus ? reviewStatus.StatusCount : 0);
    const progessStatus = res?.data?.Status.find(isStatusInProgress);
    setProgressStatusCount(progessStatus ? progessStatus.StatusCount : 0);
    const internallytStatus = res?.data?.Status.find(isStatusInternallyEnriched);
    setInternallyStatusCount(internallytStatus ? internallytStatus.StatusCount : 0);
    const externallytStatus = res?.data?.Status.find(isStatusExternallyEnriched);
    setExternallyStatusCount(externallytStatus ? externallytStatus.StatusCount : 0);
    const externalReviewtStatus = res?.data?.Status.find(isStatusExternallyReviewed);
    setExternalReviewStatusCount(externalReviewtStatus ? externalReviewtStatus.StatusCount : 0);
    const internalReviewtStatus = res?.data?.Status.find(isStatusInternallyReviewed);
    setInternalReviewStatusCount(internalReviewtStatus ? internalReviewtStatus.StatusCount : 0);
    const pendingInternalStatus = res?.data?.Status.find(isStatusPendingInternal);
    setInternalPendingStatusCount(pendingInternalStatus ? pendingInternalStatus.StatusCount : 0);
    const pendingExternalStatus = res?.data?.Status.find(isStatusPendingExternal);
    setExternalPendingStatusCount(pendingExternalStatus ? pendingExternalStatus.StatusCount : 0);
    const progressExternalStatus = res?.data?.Status.find(isStatusExternallyProgress);
    setExternalProgressStatusCount(progressExternalStatus ? progressExternalStatus.StatusCount : 0);
    const progressInternalStatus = res?.data?.Status.find(isStatusInternallyProgress);
    setInternalProgressStatusCount(progressInternalStatus ? progressInternalStatus.StatusCount : 0);
    const InternalEsgReviewStatus = res?.data?.Status.find(isStatusInternalEsgReview);
    setInternalReviewEsgStatusCount(InternalEsgReviewStatus ? InternalEsgReviewStatus.StatusCount : 0);
    const ExternalEsgReviewStatus = res?.data?.Status.find(isStatusExternalEsgReview);
    setExternalReviewEsgStatusCount(ExternalEsgReviewStatus ? ExternalEsgReviewStatus.StatusCount : 0);
    const SupplierEsgReviewStatus = res?.data?.Status.find(isStatusInReviewEsg);
    setSupplierReviewEsgStatusCount(SupplierEsgReviewStatus ? SupplierEsgReviewStatus.StatusCount : 0);
    const approvedExternalStatus = res?.data?.Status.find(isStatusExternalApproved);
    setExternalApprovedStatusCount(approvedExternalStatus ? approvedExternalStatus.StatusCount : 0);
    const approvedInternalStatus = res?.data?.Status.find(isStatusInternalApproved);
    setInternalApprovedStatusCount(approvedInternalStatus ? approvedInternalStatus.StatusCount : 0);

    console.log("status",res?.data);
  }
  

  const getSupplierItemStatuses = () =>
  {
    let url = `${Config.apiBaseAddress}/api/item/aggregateStatus`;
    
    // if (isManufacturer) {
    //   url += `?idType=manufacturer`;
    // }

    //let body = isManufacturer ? manufacturerIds?.map((data, k) => data?.id) : supplierIds?.map((data, k) => data?.id);
    let body;
      if (manufacturerIds && manufacturerIds.length > 0 && supplierIds && supplierIds.length > 0) {
        // Both manufacturerIds and supplierIds are available
        url += `?idType=manufacturer,supplier`;
        body = [
          ...manufacturerIds.map((data) => data?.id),
          ...supplierIds.map((data) => data?.id)
        ];
      } else if (manufacturerIds && manufacturerIds.length > 0) {
        // Only manufacturerIds are available
        url += `?idType=manufacturer`;
        body = manufacturerIds.map((data) => data?.id);
      } else if (supplierIds && supplierIds.length > 0) {
        // Only supplierIds are available
        url += `?idType=supplier`;
        body = supplierIds.map((data) => data?.id);
      }
    
    axios.post(url, body,
    {
      headers: 
      {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
        "Access-control-allow-origin": "*",
        Authorization: `Bearer ${token}`,
      },
    },)
    .then(populateItemStatuses)
    .catch((e) => 
    {
      // console.log(e.response?.statusText);
    });
  };
  
    useEffect(() => {
      if (isSupplier(Role) || isInternalEnricher(Role) || isExternalEnricher(Role) || isQuality(Role) || isESG(Role))  {
        getSupplierItemStatuses();
        
      } else if (isApproverOrViewer(Role)) {
        getApproverItemStatuses();
      }
    }, [UserId, supplierIds]);

    useEffect(() =>{
      if(isESG(Role))
      {
        getSupplierStatusCount();
      }
    },[UserId]
    );
    useEffect(() =>{
      if(isESG(Role))
      {
        getHFMAggregateStatusCount();
      }
    },[UserId]
    );

  const getApproverItemStatuses = () =>
  {
    axios.get (`${Config.apiBaseAddress}/api/GetStatus`,
    {
     headers: 
     {
       "Content-Type": "application/json",
       "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
       "Access-control-allow-origin": "*",
       Authorization: `Bearer ${token}`,
     },
    },)
    .then(populateItemStatuses)
    .catch((e) => 
    {
      // console.log(e.response?.statusText);
    });
   };

   const getSupplierStatusCount = () =>
    {
      axios.get (`${Config.apiBaseAddress}/api/getSupplierAggregationCount`,
      {
       headers: 
       {
         "Content-Type": "application/json",
         "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
         "Access-control-allow-origin": "*",
         Authorization: `Bearer ${token}`,
       },
      },)
      .then(populateUserStatuses)
      .catch((e) => 
      {
        // console.log(e.response?.statusText);
      });
     };

     const getHFMAggregateStatusCount = () =>
      {
        axios.get (`${Config.apiBaseAddress}/api/GetHFMAggregateCount`,
        {
         headers: 
         {
           "Content-Type": "application/json",
           "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
           "Access-control-allow-origin": "*",
           Authorization: `Bearer ${token}`,
         },
        },)
        .then(populateHFMStatuses)
        .catch((e) => 
        {
          // console.log(e.response?.statusText);
        });
       };
   
  return (
    <>
{isInternalEnricher(Role) && (
    <div className={classes["item-status-container"]}>
     <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "purple"}}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>All Items</p>
        </div>
        <p className={classes[`item-status-number`]}>{totalStatusCount}</p>
      </div>
    </div>
    <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Pending Internal Enrichment</p>\
        </div>
        <p className={classes[`item-status-number`]}>{pendingStatusCount}</p>
      </div>
    </div>

    <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internal Enrichment In-Progress</p>
        </div>
        <p className={classes[`item-status-number`]}>{progressStatusCount}</p>
      </div>
    </div>
    </div>
   )}
   {isExternalEnricher(Role) && (
    <div className={classes["item-status-container"]}>
     <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "purple"}}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>All Items</p>
        </div>
        <p className={classes[`item-status-number`]}>{totalStatusCount}</p>
      </div>
    </div>
    <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Pending External Enrichment</p>
        </div>
        <p className={classes[`item-status-number`]}>{pendingStatusCount}</p>
      </div>
    </div>

    <div>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>External Enrichment In-Progress</p>
        </div>
        <p className={classes[`item-status-number`]}>{progressStatusCount}</p>
      </div>
    </div>
    </div>
   )}

    {isSupplier(Role) && (
    <div className={classes["item-status-container"]}>
     <div>
      <h5 className={classes["item-status-header"]}>Total Items</h5> 
      <div className={classes["item-status-card"]} style={{backgroundColor: "#FBE3D6"}}>
      <p className={classes[`item-status-text`]}>{totalStatusCount}</p>
      </div>
    </div>
    <ForwardIcon sx={{ visibility: "hidden", fontSize: 80}}/>
    <div>
      <h5 className={classes["item-status-header"]}>Pending</h5>
      <div className={classes["item-status-card"]} style={{backgroundColor: "#FBE3D6"}}>
        <p className={classes[`item-status-text`]}>{pendingStatusCount}</p>
      </div>
    </div>
    <ForwardIcon className={classes.arrow} sx={{ fontSize: 80}}/>
    <div>
      <h5 className={classes["item-status-header"]}>In Progress</h5>
      <div className={classes["item-status-card"]} style={{backgroundColor: "#FBE3D6"}}>
        <p className={classes[`item-status-text`]}>{progressStatusCount}</p>
      </div>
    </div>
    <ForwardIcon className={classes.arrow} sx={{ fontSize: 80}}/>
    <div>
      <h5 className={classes["item-status-header"]}>In Review</h5> 
      <div className={classes["item-status-card"]} style={{backgroundColor: "#CAEEFB"}} >
        <p className={classes[`item-status-text`]}>{reviewStatusCount}</p>
      </div>
    </div>
    <ForwardIcon className={classes.arrow} sx={{ fontSize: 80}}/>
    <div>
      <h5 className={classes["item-status-header"]}>Approved</h5> 
      <div className={classes["item-status-card"]} style={{backgroundColor: "#D9F2D0"}}>
        <p className={classes[`item-status-text`]}>{approvedStatusCount}</p>
      </div>
    </div>
    </div>
    )}
    {isQuality(Role) && (
    <div className={classes["quality-item-status-container"]}>
     <div style={{ gridArea: "a" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "purple"}}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>All Items</p>
        </div>
        <p className={classes[`item-status-number`]}>{totalStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "b" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "purple" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Supplier Enriched</p>
        </div>
        <p className={classes[`item-status-number`]}>{approvedStatusCount + reviewStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "c" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "purple" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internally Enriched</p>
        </div>
        <p className={classes[`item-status-number`]}>{internallyStatusCount + internalReviewStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "d" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "purple" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '24px' }}>Externally Enriched</p>
        </div>
        <p className={classes[`item-status-number`]}>{externallyStatusCount + externalReviewStatusCount}</p>
      </div>
    </div>


    <div style={{ gridArea: "e" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "blue" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Pending Internal Enrichment</p>
        </div>
        <p className={classes[`item-status-number`]}>{internalPendingStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "f" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "blue" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internal Enrichment In-Progress</p>
        </div>
        <p className={classes[`item-status-number`]}>{internalProgressStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "g" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "blue" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internal Enrichment In-Review</p>
        </div>
        <p className={classes[`item-status-number`]}>{internallyStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "h" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "skyblue" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internally Enriched Validated</p>
        </div>
        <p className={classes[`item-status-number`]}>{internalReviewStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "i" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "orange" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Internally Enriched Error Handling</p>
        </div>
        <p className={classes[`item-status-number`]}>{InternalreviewEsgStatusCount}</p>
      </div>
    </div>


    <div style={{ gridArea: "j" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>Pending External Enrichment</p>
        </div>
        <p className={classes[`item-status-number`]}>{externalPendingStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "k" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>External Enrichment In-Progress</p>
        </div>
        <p className={classes[`item-status-number`]}>{externalProgressStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "l" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "green" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>External Enrichment In-Review</p>
        </div>
        <p className={classes[`item-status-number`]}>{externallyStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "m" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "skyblue" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '24px' }}>Externally Enriched Validated</p>
        </div>
        <p className={classes[`item-status-number`]}>{externalReviewStatusCount}</p>
      </div>
    </div>
    <div style={{ gridArea: "n" }}>
      <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{ backgroundColor: "orange" }}>
        <div className={classes["header"]}>
          <p className={classes[`item-status-text`]}style={{ fontSize: '24px' }}>Externally Enriched Error Handling</p>
        </div>
        <p className={classes[`item-status-number`]}>{externalreviewEsgStatusCount}</p>
      </div>
    </div>    
    </div>
   )}

  {isESG(Role) && (
    <div className={classes["esg-item-status-container"]} >
        <div>
          <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "darkcyan"}}>
            <div className={classes["header"]}>
              <p className={classes["item-status-text"]} style={{ fontSize: '23px' }}>Pending Supplier Enrichment</p>
            </div>
            <p className={classes["item-status-number"]}>{pendingStatusCount}</p>
          </div>
        </div>
        <div>
          <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "darkcyan"}}>
            <div className={classes["header"]}>
              <p className={classes["item-status-text"]} style={{ fontSize: '22px' }}>Supplier Enrichment In-Progress</p>
            </div>
            <p className={classes["item-status-number"]}>{progressStatusCount}</p>
          </div>
        </div>
        <div>
          {/* <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "Grey"}}>
            <div className={classes["header"]}>
              <p className={classes["item-status-text"]} style={{ fontSize: '22px' }}>Status Counts</p>
            </div>
            <p className={classes["item-status-number"]}>{}</p>
          </div> */}
        </div>
        <div>
          <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "steelblue"}}>
            <div className={classes["header"]}>
              <p className={classes["item-status-text"]} style={{ fontSize: '23px' }}>Supplier Enriched Approved</p>
            </div>
            <p className={classes["item-status-number"]}>{approvedStatusCount}</p>
          </div>
        </div>
        <div>
          <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
            <div className={classes["header"]}>
              <p className={classes["item-status-text"]} style={{ fontSize: '23px' }}>Supplier Enriched Error Handling</p>
            </div>
            <p className={classes["item-status-number"]}>{reviewStatusCount}</p>
          </div>
        </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "navy"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>Pending Internal Enrichment</p>
          </div>
          <p className={classes[`item-status-number`]}>{internalPendingStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "navy"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>Internal Enrichment In-Progress</p>
          </div>
          <p className={classes[`item-status-number`]}>{internalProgressStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "navy"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>Internal Enrichment In Review</p>
          </div>
          <p className={classes[`item-status-number`]}>{internallyStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "steelblue"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>Internally Enriched Validated</p>
          </div>
          <p className={classes[`item-status-number`]}>{internalReviewStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>Internally Enriched Error Handling</p>
          </div>
          <p className={classes[`item-status-number`]}>{InternalreviewEsgStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "yellowgreen"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>Pending External Enrichment</p>
          </div>
          <p className={classes[`item-status-number`]}>{externalPendingStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "yellowgreen"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>External Enrichment In-Progress</p>
          </div>
          <p className={classes[`item-status-number`]}>{externalProgressStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "yellowgreen"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>External Enrichment In Review</p>
          </div>
          <p className={classes[`item-status-number`]}>{externallyStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "steelblue"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>Externally Enriched Validated</p>
          </div>
          <p className={classes[`item-status-number`]}>{externalReviewStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '22px' }}>Externally Enriched Error Handling</p>
          </div>
          <p className={classes[`item-status-number`]}>{externalreviewEsgStatusCount}</p>
        </div>
      </div>

      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "plum"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '28px' }}>All Items</p>
          </div>
          <p className={classes[`item-status-number`]}>{totalStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "plum"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '27px' }}>Total Suppliers Onboarded</p>
          </div>
          <p className={classes[`item-status-number`]}>{supplierStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "plum"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '27px' }}>Supplier Users Onboarded</p>
          </div>
          <p className={classes[`item-status-number`]}>{supplierUserStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "plum"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '28px' }}>Total Active Users</p>
          </div>
          <p className={classes[`item-status-number`]}>{activeUserStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>8888 - Error Handling</p>
          </div>
          <p className={classes[`item-status-number`]}>{errorStatusCount}</p>
        </div>
      </div>

      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>7810 - Alternative Materials</p>
          </div>
          <p className={classes[`item-status-number`]}>{materialStausCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>7820 - Important Purpose</p>
          </div>
          <p className={classes[`item-status-number`]}>{purposeStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>7830 - Consumables likely to Transition</p>
          </div>
          <p className={classes[`item-status-number`]}>{transitionStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '23px' }}>7840 - Consumables Facing Regulation</p>
          </div>
          <p className={classes[`item-status-number`]}>{regulationStatusCount}</p>
        </div>
      </div>
      <div>
        <div className={`${classes["item-status-card"]} ${classes["enricher"]}`} style={{backgroundColor: "chocolate"}}>
          <div className={classes["header"]}>
            <p className={classes[`item-status-text`]}style={{ fontSize: '25px' }}>9999 - Non Packaging</p>
          </div>
          <p className={classes[`item-status-number`]}>{packingStatusCount}</p>
        </div>
      </div>
    </div>
  )}
  </>
  );
}

export default ItemStatuses;